.green{
    background: var(--color-paymeGreen);
}

.text-green{
    color: var(--color-paymeGreen) !important;
}

.projects .h3-blue-logo{
    position: relative;
    padding-left: 3.5rem;
    white-space: nowrap;
}

.projects .h3-blue-logo::before{
    content: '';
    height: 50%;
    width: 1.5rem;
    border: 2px solid var(--color-newBlue);
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.projects-payme-idea-top{
    z-index: -2;
    min-height: 50vh;
    position: relative;
    padding: var(--main-space) var(--container-space);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    background: url('../../images/projects/payme/projects-payme-intro-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-white);
    gap: var(--main-space);
}

.projects-payme-idea-top::after{
    content: '';
    z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color-white);
    opacity: .1;
}

.projects-payme-idea-top > div{
    margin: 0 auto;
    padding: var(--main-space) 0;
}

.projects-payme-idea-top h2{
    font-family: 'Homanje', sans-serif;
    font-size: 9rem;
    font-weight: 400;
}

.projects-payme-idea-top > img{
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
    object-fit: contain;
}

.projects-payme-user-research{
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-payme-idea-box, .projects-payme-analyzing-box, .projects-payme-flowchart-box{
    padding: var(--main-space) var(--container-space);
    display: flex;
    flex-flow: row nowrap;
}

.projects-payme-idea-box:first-child{
    padding-top: var(--section-space);
}

.projects-payme-idea-box ul.row{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: var(--main-space);
}

.projects-payme-idea-box ul.row li{
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-payme-idea-box-a{
    padding: calc(var(--section-space) * 2) var(--container-space);
}

.projects-payme-idea-box-b{
    color: var(--color-white);
}

.projects-payme-idea-box-c{
    margin-top: var(--section-space);
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
    color: var(--color-white);
}

.projects-payme-idea-user-research{
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-payme-idea-box-c li > h2{
    font-size: 40px;
    font-weight: 400;
    color: var(--color-newBlue);
}

.projects-payme-idea-box-d{
    padding: calc(var(--section-space) * 2) var(--container-space) var(--section-space) var(--container-space);
}

.projects-payme-idea-box-d li:first-child{
    padding: var(--main-space) 0;
}

.projects-payme-idea-box-d-value-proposition{
    padding: var(--main-space);
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
    border-radius: 6px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
}

.projects-payme-analyzing-persona{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.projects-payme-analyzing-persona > h3{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
}

.projects-payme-analyzing-persona-quote{
    font-size: 4rem;
    text-align: center;
    font-weight: 600;
    color: var(--color-newBlue);
}

.projects-payme-analyzing-arik-info{
    padding: var(--main-space);
    border-radius: 8px;
    background: #4A7984;
    color: var(--color-white);
    display: grid;
    grid-template-areas:
        "a a c"
        "b b c"
        "d d e";
    gap: var(--main-space);
}

.projects-payme-analyzing-arik-info-grid-a{
    grid-area: a;
}

.projects-payme-analyzing-arik-info-grid-b{
    grid-area: b;
}

.projects-payme-analyzing-arik-info-grid-c{
    grid-area: c;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projects-payme-analyzing-arik-info-grid-d{
    grid-area: d;
}

.projects-payme-analyzing-arik-info-grid-e{
    grid-area: e;
}

.projects-payme-analyzing-arik-info h3{
    margin-bottom: var(--main-space);
}

.projects-payme-analyzing-arik-info-grid-b th, td{
    text-align: left;
    padding-right: var(--main-space);
}

.projects-payme-analyzing-arik-info-grid-b th{
    padding-bottom: calc(var(--main-space) / 4);
}

.projects-payme-analyzing-arik-info-grid-c img{
    height: 150px;
    width: 150px;
}

.projects-payme-flowchart-box-a{
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-payme-flowchart-box-a h4{
    font-size: 3.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
    color: var(--color-darkBlue);
    opacity: 0.5;
}

.projects-payme-flowchart-box-a img{
    max-height: 71px;
    width: 100%;
}

.projects-payme-flowchart-box-b{
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-payme-flowchart-box-b div{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.projects-payme-flowchart-box-b img{
    width: 75%;
}

@media only screen and (max-width: 768px){
    .projects-payme-analyzing-arik-info{
        grid-template-areas: 
            "c"
            "b"
            "a"
            "d"
            "e"
        ;
    }

    .projects-payme-flowchart-box-b img{
        width: 100%;
    }
}