.projects .h3-purple-logo{
    position: relative;
    padding-left: 3.5rem;
    white-space: nowrap;
}

.projects .h3-purple-logo::before{
    content: '';
    height: 50%;
    width: 1.5rem;
    border: 2px solid var(--color-purpleHat);
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.projects-tourista-idea-top{
    z-index: -2;
    min-height: 50vh;
    position: relative;
    padding: var(--main-space) var(--container-space) 0 var(--container-space);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    background: url('../../images/projects/tourista/projects-tourista-intro-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-white);
    gap: var(--main-space);
}

.projects-tourista-idea-top::after{
    content: '';
    z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color-white);
    opacity: .1;
}

.projects-tourista-idea-top > div{
    margin: 0 auto;
    padding: var(--main-space) 0;
}

.projects-tourista-idea-top > div > p{
    padding: 0 1rem;
}

.projects-tourista-idea-top h2{
    font-family: 'Poppins', sans-serif;
    font-size: 9rem;
    font-weight: 500;
}

.projects-tourista-idea-top > img{
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
    object-fit: contain;
}

.projects-tourista-idea-box, .projects-tourista-analyzing-box, .projects-tourista-flowchart-box, .projects-tourista-app-box{
    padding: var(--main-space) var(--container-space);
}

.projects-tourista-idea-box:first-child{
    padding-top: var(--section-space);
}

.projects-tourista-app-box:first-child{
    margin-top: var(--section-space);
}

.projects-tourista-idea-box ul.row{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: var(--main-space);
}

.projects-tourista-idea-box ul.row li{
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-tourista-idea-box-a{
    padding: calc(var(--section-space) * 2) var(--container-space);
}

.projects-tourista-idea-box-a ul, .projects-tourista-idea-box-b ul, .projects-tourista-idea-box-c ul{
    list-style: none;
}

.projects-tourista-idea-box-a h3, .projects-tourista-idea-box-b h3, .projects-tourista-idea-box-c h3{
    font-weight: 600;
}

.projects-tourista-idea-box-b{
    display: flex;
    flex-flow: column nowrap;
    color: var(--color-white);
    row-gap: var(--main-space);
}

.projects-tourista-idea-box-b h2{
    color: var(--color-lemonade);
    font-size: 8rem;
    font-weight: 400;
}

.projects-tourista-idea-box-c{
    margin-top: calc(var(--section-space) * 2);
    display: flex;
    flex-flow: row nowrap;
    color: var(--color-white);
    row-gap: var(--main-space);
    padding-top: 0;
    padding-bottom: 0;
}

.projects-tourista-idea-box-c img{
    height: 300px;
}

.projects-tourista-idea-box-c h2{
    color: var(--color-lemonade);
    font-size: 8rem;
    font-weight: 400;
}

.projects-tourista-idea-market-research{
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
    margin: var(--main-space);
}

.projects-tourista-app-box-a{
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-tourista-app-images-box{
    display: flex;
    flex-flow: column nowrap;
    row-gap: calc(var(--section-space) * 2);
}

@media only screen and (max-width: 768px){
    .projects-tourista-idea-box-c{
        flex-flow: column nowrap;
        margin: var(--main-space);
        padding: var(--main-space);
    }
}