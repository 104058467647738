@import url('https://fonts.googleapis.com/css2?family=Homenaje&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@1,500&family=Syne:wght@700&display=swap');

:root{
    --color-white: #FFFFFF;
    --color-gray: #727070;
    --color-red: #FB2C2C;
    --color-lemonade: #D7FF42;
    --color-purpleHat: #BD40C8;
    --color-darkBlue: #14111D;
    --color-perfectBlue: #15333A;
    --color-lightBlue: #A4C3D1;
    --color-veryLightBlue: #F1EFF6;
    --color-newBlue: #5B7D96;
    --color-paymeGreen: #5FB671;

    --container-space: 10vw;
    --content-space: 10vw;
    --main-space: 6rem;
    --paragraph-space: calc(var(--main-space) / 2);
    --section-space: calc(var(--main-space) * 2);
}

*{
    box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, h6, ul, ol, li, p{
    margin: 0;
    padding: 0;
}

a{
    text-decoration: none;
}

button{
    border: none;
    background: none;
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
    cursor: pointer;
}

figure{
    margin: 0;
}

table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

html{
    font-size: 4pt;
}

body{
    min-height: 100vh;
    background: var(--color-white);
    color: var(--color-darkBlue);
    font-family: 'Montserrat', sans-serif;
    font-size: 3em;
}

header{
    min-height: 100px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.header-logo{
    flex: 1;
    height: 100px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.header-logo h1{
    position: relative;
    padding-left: 4rem;
    font-weight: 500;
    font-size: 4rem;
}

.header-logo h1::before{
    content: '';
    height: 50%;
    width: 3rem;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    background: var(--color-red);
    transform: translate(0, -50%);
}

.header-logo a, .header-logo a:visited, .header-logo a:focus, .header-logo a:hover{
    color: #000000;
}

.header-nav-trigger{
    height: 15px;
    width: 15px;
    position: relative;
    display: none;
}

.header-nav-trigger img{
    position: absolute;
    top: 0;
    right: 0;
    transition: all .5s;
}

.header-hamburger-opened{
    opacity: 0;
}

.header-nav-hidden{
    display: block;
}

header > nav ul{
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    column-gap: var(--main-space);
}

header > nav a{
    position: relative;
    padding: 1.5rem 0;
    color: var(--color-darkBlue);
}

header > nav a.active{
    font-weight: 600;
}

header > nav a.active::before{
    content: '';
    height: 2px;
    width: 80%;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 50%;
    background: var(--color-red);
    transform: translate(-50%, 0);
}

.container-space{
    padding: 0 var(--container-space);
}

.home-greet{
    min-height: calc(100vh - 100px);
    border-bottom: 1px solid var(--color-veryLightBlue);
    padding: var(--section-space) var(--content-space);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    row-gap: var(--main-space);
}

.home-greet figure{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: var(--main-space);
}

.home-greet-figure-image{
    height: 320px;
    width: 260px;
    position: relative;
}

.home-greet-figure-image img{
    height: 300px;
    width: 240px;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    animation: 0.3s ease-out 0s 1 pinchImage;
}

@keyframes pinchImage{
    0%{
        right: 50%;
        bottom: 50%;
        transform: translate(50%, 50%);
    }
    100%{
        right: 0;
        bottom: 0;
        transform: unset;
    }
}

.home-greet-figure-border{
    content: '';
    height: 300px;
    width: 240px;
    box-sizing: unset;
    border: 4px solid var(--color-darkBlue);
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    animation: 0.3s ease-out 0s 1 pinchBorder;
}

@keyframes pinchBorder{
    0%{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    100%{
        top: 0;
        left: 0;
        transform: unset;
    }
}

.home-greet figcaption{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    font-size: 4rem;
    white-space: nowrap;
}

.home-greet figcaption h2{
    color: var(--color-red);
    font-weight: 500;
}

.home-greet-social-buttons{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    column-gap: 2rem;
}

.column-paragraph-container{
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--paragraph-space);
}

.home-article{
    border-bottom: 1px solid var(--color-veryLightBlue);
    padding: var(--section-space) var(--content-space);
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.home-article:last-child{
    border-bottom: 0;
}

.home-article-top{
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    column-gap: calc(var(--main-space) * 2);
    row-gap: var(--main-space);
}

.home-article-description{
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    white-space: nowrap;
    row-gap: var(--main-space);
}

.home-article-description h2{
    font-size: 8rem;
    font-weight: 500;
}

.home-article-description-light{
    color: var(--color-gray);
    font-weight: 300;
}

.home-article-more{
    display: flex;
    flex-flow: column nowrap;
    color: var(--color-gray);
    font-weight: 300;
    white-space: nowrap;
    row-gap: var(--main-space);
}

.home-article-more-text{
    flex: 1;
}

.home-article-more-text h3{
    font-weight: 300;
}

.home-article-more a{
    border-radius: 8px;
    padding: 2rem;
    font-weight: 600;
}

.home-lets-talk .home-article-top img{
    height: 200px;
    width: 200px;
}

.home-article > img{
    width: 75%;
    align-self: center;
}

.home-app-momentum h2{
    font-family: 'Syne', sans-serif;
    font-weight: 700;
}

.home-app-momentum-button{
    background: var(--color-red);
    color: var(--color-white);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.home-app-momentum-button span:last-child{
    padding: 0 calc(var(--main-space) / 4);
}

.home-app-payme h2{
    font-family: 'Homenaje', sans-serif;
    font-weight: 400;
}

.home-app-payme-button{
    background: var(--color-red);
    color: var(--color-white);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.home-app-payme-button span:last-child{
    padding: 0 calc(var(--main-space) / 4);
}

.home-app-tourista h2{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.home-app-tourista-button{
    background: var(--color-red);
    color: var(--color-white);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.home-app-tourista-button span:last-child{
    padding: 0 calc(var(--main-space) / 4);
}

.home-lets-talk{
    min-height: 75vh;
}

.home-lets-talk-description{
    flex: 4;
}

.home-lets-talk > button{
    width: min-content;
    border: none;
    display: flex;
    flex-flow: column nowrap;
    align-self: center;
    text-align: center;
    background: none;
    color: var(--color-red);
    font-family: 'Montserrat', sans-serif;
    white-space: nowrap;
    cursor: pointer;
}

.home-lets-talk-image{
    flex: 1;
    display: flex;
    justify-content: center;
}

.home-lets-talk > button > span:first-child{
    width: 100%;
    font-size: 8rem;
    transform: rotate(180deg);
}

section > div:last-child{
    padding-bottom: var(--section-space);
}

.perfect-blue{
    background: var(--color-perfectBlue);
}

.light-blue{
    background: var(--color-lightBlue);
}

.idea-box-inside-container{
    padding: var(--main-space);
    border-radius: 6px;
}

.projects section .last-in-section{
    border-bottom: 1px solid var(--color-veryLightBlue);
    margin: 0 var(--container-space);
}

.projets-scroll-top-button{
    width: 100%;
    margin: var(--main-space) 0;
    display: flex;
    justify-content: center;
}

.projets-scroll-top-button button{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    row-gap: calc(var(--main-space) / 2);
}

.projets-scroll-top-button button h2{
    font-weight: 400;
}

.projets-scroll-top-button button img{
    height: 50%;
    object-fit: contain;
}

.about{
    padding-top: var(--main-space);
    padding-bottom: var(--main-space);
    display: flex;
    flex-flow: row nowrap;
    gap: var(--main-space);
}

.about > h2{
    flex: 1;
    font-size: 8rem;
    font-weight: 500;
    white-space: nowrap;
}

.about > h2 .red{
    color: var(--color-red);
}

.about-words{
    display: flex;
    flex-flow: row wrap;
    row-gap: var(--main-space);
}

.about-buttons{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--main-space);
}

.about-download-button{
    border-radius: 8px;
    padding: 2rem;
    display: block;
    background: var(--color-red);
    color: var(--color-white);
    font-weight: 600;
}

.about-social-buttons{
    display: flex;
    flex-flow: row nowrap;
    column-gap: calc(var(--main-space) / 2);
}

.not-found{
    min-height: calc(100vh - 100px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: justify;
}

.not-found h2{
    font-size: 30rem;
    color: var(--color-red);
}

.not-found h3{
    font-size: 8rem;
}

@media only screen and (max-width: 1500px){
    .dividing-line{
        display: none;
    }
}

@media only screen and (max-width: 768px){
    header{
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr;
        justify-content: center;
    }

    .header-nav-trigger{
        display: block;
    }

    .header-nav-hidden{
        display: none;
    }

    header > nav{
        width: 100%;
        padding: 0 0 var(--main-space) 0;
    }

    header > nav ul{
        flex-flow: column nowrap;
    }

    header > nav li{
        text-align: center;
    }

    header > nav a:hover{
        background: var(--color-veryLightBlue);
    }

    header > nav a{
        display: block;
        font-size: 6rem;
    }
    
    header > nav a.active::before{
        height: 100%;
        width: 2px;
        left: 0;
    }

    header > nav a.active{
        background: var(--color-veryLightBlue);
    }

    .about{
        flex-flow: column nowrap;
    }
}

@media only screen and (max-width: 600px){
    html{
        font-size: 3.5pt;
    }

    .home-greet-figure-image{
        height: 250px;
        width: 197px;
    }
    
    .home-greet-figure-image img{
        height: 230px;
        width: 180px;
    }

    .home-greet-figure-border{
        height: 230px;
        width: 180px;
        border: 4px solid var(--color-darkBlue);
    }
}

@media only screen and (max-width: 400px){
    :root{
        --content-space: 0;
    }
}