.projects .h3-green-logo{
    position: relative;
    padding-left: 3.5rem;
    white-space: nowrap;
}

.projects .h3-green-logo::before{
    content: '';
    height: 50%;
    width: 1.5rem;
    border: 2px solid var(--color-lemonade);
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.projects-momentum-idea-top{
    z-index: -2;
    min-height: 50vh;
    position: relative;
    padding: var(--main-space) var(--container-space);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    background: url('../../images/projects/momentum/projects-momentum-intro-bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-white);
    gap: var(--main-space);
}

.projects-momentum-idea-top::after{
    content: '';
    z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color-white);
    opacity: .1;
}

.projects-momentum-idea-top > div{
    margin: 0 auto;
    padding: var(--main-space) 0;
}

.projects-momentum-idea-top h2{
    font-family: 'Syne', sans-serif;
    font-size: 9rem;
    font-weight: 700;
}

.projects-momentum-idea-top > img{
    height: 100%;
    max-width: 100%;
    margin: 0 auto;
    object-fit: contain;
}

.projects-momentum-idea-box, .projects-momentum-analyzing-box, .projects-momentum-flowchart-box, .projects-momentum-app-box{
    padding: var(--main-space) var(--container-space);
}

.projects-momentum-idea-box:first-child, .projects-momentum-analyzing-box:first-child, .projects-momentum-flowchart-box:first-child, .projects-momentum-app-box:first-child{
    padding-top: var(--section-space);
}

.projects-momentum-idea-box ul.row{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: var(--main-space);
}

.projects-momentum-idea-box ul.row li{
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-momentum-idea-box-a{
    padding: calc(var(--section-space) * 2) var(--container-space);
}

.projects-momentum-idea-box-a ul, .projects-momentum-idea-box-b ul, .projects-momentum-idea-box-c ul{
    list-style: none;
}

.projects-momentum-idea-box-a h3, .projects-momentum-idea-box-b h3, .projects-momentum-idea-box-c h3{
    font-weight: 600;
}

.projects-momentum-idea-box-b{
    display: flex;
    flex-flow: column nowrap;
    color: var(--color-white);
    row-gap: var(--main-space);
}

.projects-momentum-idea-box-b h2{
    color: var(--color-lemonade);
    font-size: 8rem;
    font-weight: 400;
}

.projects-momentum-idea-user-research{
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-momentum-analyzing-box-a{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    row-gap: var(--main-space);
}

.projects-momentum-analyzing-box-a > h3{
    width: 100%;
}

.projects-momentum-analyzing-box-a > h2{
    color: #BBDE39;
}

.projects-momentum-analyzing-ariel{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.projects-momentum-analyzing-ariel-avatar{
    height: 16rem;
    position: relative;
}

.projects-momentum-analyzing-ariel-avatar > img{
    height: 200%;
    border: 4px solid var(--color-lemonade);
    border-radius: 100%;
    position: absolute;
    right: var(--main-space);
}

.projects-momentum-analyzing-ariel-info{
    border-radius: 8px;
    direction: rtl;
    padding: calc(var(--main-space) + 16rem) var(--main-space) var(--main-space) var(--main-space);
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content;
    text-align: left;
    color: var(--color-white);
    gap: var(--main-space);
}

.projects-momentum-analyzing-ariel-info h3{
    color: var(--color-lemonade);
    font-weight: 600;
}

.projects-momentum-analyzing-ariel-info-aspect{
    direction: ltr;
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-momentum-analyzing-ariel-info-aspect th{
    padding-bottom: var(--main-space);
}

.projects-momentum-analyzing-ariel-info-aspect th, .projects-momentum-analyzing-ariel-info-aspect td{
    padding-right: calc(var(--main-space) / 2);
    text-align: left;
}

.projects-momentum-analyzing-ariel-info-aspect ul{
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-momentum-analyzing-ariel-info-aspect-general{
    white-space: nowrap;
}

.projects-momentum-analyzing-ariel-info-aspect-general table,
.projects-momentum-analyzing-ariel-info-aspect-general caption,
.projects-momentum-analyzing-ariel-info-aspect-general tbody,
.projects-momentum-analyzing-ariel-info-aspect-general tfoot,
.projects-momentum-analyzing-ariel-info-aspect-general thead,
.projects-momentum-analyzing-ariel-info-aspect-general tr,
.projects-momentum-analyzing-ariel-info-aspect-general th,
.projects-momentum-analyzing-ariel-info-aspect-general td{
    display: flex;
}

.projects-momentum-analyzing-ariel-info-aspect-general tbody{
    flex-flow: column nowrap;
}

.projects-momentum-analyzing-box-b{
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-momentum-analyzing-box-b table, .projects-momentum-analyzing-box-b tbody, .projects-momentum-analyzing-box-b tr{
    width: 100%;
}

.projects-momentum-analyzing-box-b td{
    text-align: center;
}

.projects-momentum-analyzing-box-b .h3-thin{
    font-weight: 500;
}

.projects-momentum-analyzing-box-b ol{
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
    list-style: none;
    counter-reset: item;
}

.projects-momentum-analyzing-box-b li{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    counter-increment: item;
}

.projects-momentum-analyzing-box-b li::before{
    content: '0' counter(item);
    margin: auto 3rem auto 0;
    color: var(--color-lemonade);
    font-size: 2em;
    text-align: center;
}

.projects-momentum-flowchart-box{
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

.projects-momentum-flowchart-task-flow ul.column{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    list-style: none;
    row-gap: var(--main-space);
}

.projects-momentum-flowchart-task-flow ul.column h3{
    margin-bottom: calc(var(--main-space) / 2);
    font-weight: 400;
}

.projects-momentum-flowchart-task-flow ul.column li img{
    width: 100%;
    max-width: 1000px;
}

.projects-momentum-flowchart-box-b img{
    width: 100%;
    max-width: 1000px;
    align-self: center;
}

.projects-momentum-app-box-a, .projects-momentum-app-box-b{
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--main-space);
}

section.projects-momentum-app img{
    width: 100%;
}

.projects-momentum-app-images-box{
    display: flex;
    flex-flow: column nowrap;
    row-gap: var(--section-space);
}

.projects-momentum-app-image-box-push-from-right{
    padding-right: var(--container-space);
}

.projects-momentum-app-image-box-push-from-left{
    padding-left: var(--container-space);
}

@media only screen and (max-width: 768px){
    .projects-momentum-idea-top{
        grid-template-columns: 1fr;
    }

    .projects-momentum-idea-box ul.row li{
        min-width: 100%;
    }

    .projects-momentum-analyzing-ariel-avatar > img{
        right: 50%;
        transform: translate(50%, 0);
    }

    .projects-momentum-analyzing-ariel-info{
        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content min-content min-content;
    }

    .projects-momentum-analyzing-ariel-info-aspect-general{
        white-space: unset;
    }
}

@media only screen and (max-width: 600px){
    .projects-momentum-analyzing-box-b table{
        border-spacing: calc(var(--main-space) / 2);
        font-size: 7pt;
    }

    .projects-momentum-analyzing-box-b th img{
        width: 100%;
        max-width: 50px;
    }
}